import { default as $ } from 'jquery';

jQuery(document).ready(function () {
    // Map block filter
    $('#map-filter').on('change', function (e) {
        var area = $(this).find(':selected').data('area');

        if(area == 'all') {
            $('.m-map-project').show();
        } else {
            $('.m-map-project').each(function(i, obj) {
                if($(this).hasClass(area)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
        
        if($("div.m-map-project:visible").length === 0) {
            $('.m-map-none').show();
        } else {
            $('.m-map-none').hide();
        }
    });

    // Projects filter
    $('#filter-submit').on('click', function (e) {
        var area = $('#filter-location').find(':selected').data('area');
        var type = $('#project-type').find(':selected').val();

        if(area == 'all-locations') {
            $('.m-projects-project').show();
        } else {
            $('.m-projects-project').each(function(i, obj) {
                if($(this).hasClass(area)) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }

        if(type == 'all-projects') {
            $('.m-projects-container').show();
        } else {
            $('.m-projects-container').each(function(i, obj) {
                var project = $(this).attr('id');

                if(type === project) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        }
        
        if($("div.m-projects-project:visible").length === 0) {
            $('.m-filter-none').show();
            $('.m-projects-container').hide();
        } else {
            $('.m-filter-none').hide();
        }
    });
});