import 'jquery';

jQuery('#mobileNavButton').on('click', function(){
    jQuery('#mobileNavDropdown').toggleClass('-active');
});

jQuery(document).ready(function() {
 
	jQuery(".fa-search").click(function() {
       jQuery(".toggleNavsearch").toggleClass('-animate')
       .delay(100)
            .queue(function() {
                jQuery(".toggleNavsearch").toggleClass("-overflow");
                jQuery(".toggleNavsearch").dequeue();
            });
	 });
 
});