import 'jquery';
import { default as $ } from 'jquery';


$(window).on("load",function() {
    $(window).scroll(function() {
    var pageTop = $(document).scrollTop();
    var windowBottom = pageTop + $(window).height();
      $(".addAnimation").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fadeIn');
        } 
      });
    }).scroll(); //invoke scroll-handler on page-load
  });

