import 'jquery';
import 'slick-carousel';
import { default as $ } from 'jquery';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';


jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        jQuery('.m-people-carousel').addClass('-isCarousel');
    }
});

jQuery(document).ready(function () {
    $('.m-people-carousel.-isCarousel').each( function() {
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: false,
            margin: 20,
            slidesToShow: 1,
            arrows: false,
            draggable: true,
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 767,
                    
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        };

        $(this).slick(slickConf);
    });
});


jQuery(document).ready(function () {
    var collapseElementList = [].slice.call(document.querySelectorAll('.collapse'))
    var collapseList = collapseElementList.map(function (collapseEl) {
      return new bootstrap.Collapse(collapseEl)
    })
});


jQuery(document).ready(function () {      
    jQuery(".m-people-button").click(function() {
        jQuery(".m-people-modal").scrollTop(0);
    });
});