import 'jquery';

jQuery(document).ready(function () {      

    jQuery('.m-info-nav-default').click( function () {
        jQuery('#infoNavList').removeClass('-hide');

    });
    
    jQuery(document).click(function(event){
        if (!jQuery(event.target).hasClass('m-info-nav-default')) {
            jQuery("#infoNavList").addClass("-hide");
        }
    });

});