import 'jquery';
import 'slick-carousel';
import { default as $ } from 'jquery';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';


jQuery(document).ready(function () {
    $('.m-partners.-isCarousel').each( function() {
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: false,
            margin: 20,
            slidesToShow: 4,
            arrows: true,
            prevArrow: $(".m-partners-prev"),
            nextArrow: $(".m-partners-next"),
            draggable: true,
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 767,
                    
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        };

        $(this).slick(slickConf);
    });
});
