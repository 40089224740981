import { default as $ } from 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

const getArrowLongIcon = ({ arrowType }) => {
    return '<svg class="icon svg-canvas" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 24" xml:space="preserve">\n' +
        '<use xlink:href="#' + (arrowType == 'prev' ? 'arrow-rounded-l' : 'arrow-rounded-r') + '"></use>\n' +
        '</svg>';
};

$(document).ready( function() {
    $('.contentListings.-isCarousel').each( function() {
        let itemsPerRow = jQuery(this).data('itemsPerRow') ? parseInt(jQuery(this).data('itemsPerRow')) : 3;
        
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            margin: 20,
            slidesToShow: itemsPerRow,
            arrows: true,
            prevArrow: $(".m-content-listings-carousel-button-prev"),
            nextArrow: $(".m-content-listings-carousel-button-next"),
            draggable: true,
            appendDots: jQuery(this).parent(),
            appendArrows: jQuery(this).parent(),
            customPaging: function(slider, i) {
                return '<button type="button" data-role="none" role="button" tabindex="0">' + i + '</button>';
            },
            pauseOnHover: false,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 767,

                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                        dots: false,
                    }
                }
            ]
        };

        // if ($(this).hasClass('-relatedEvents')) {
        //     slickConf.prevArrow = '<button type="button" class="slick-prev">' + getArrowLongIcon({ arrowType: 'prev' }) + '</button>';
        //     slickConf.nextArrow = '<button type="button" class="slick-next">' + getArrowLongIcon({ arrowType: 'next' }) + '</button>';
        // }

        $(this).slick(slickConf);
    });

    jQuery('.card .galleryList.-isCarousel').each(function () {
        const that = this;
        const controlsEl = jQuery(this).parent().find('.galleryMeta .galleryControls');

        let slickConf = {
            ...SLICK_CONF,
            arrows: false,
            appendDots: jQuery(this),
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            lazyLoad: 'ondemand',
        };

        if(controlsEl.length > 0)
            slickConf.appendArrows = jQuery(controlsEl);

        jQuery(this).not('.slick-initialized').slick(slickConf);

        jQuery(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if(jQuery(that).parent().find('.galleryHeadList .activeItem .text').length > 0)
                jQuery(that).parent().find('.galleryHeadList .activeItem .text').text(nextSlide + 1);
        });
    });
});