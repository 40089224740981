import 'jquery';

jQuery(document).ready(function () {
    let stickyNav = function () {
        let stickyNavTop = jQuery('#header').offset().top;
        let scrollTop = jQuery(window).scrollTop(); // our current vertical position from

        if (scrollTop > 0) {
            jQuery('#header.-willBeSticky').addClass('sticky');
        } 
        else if(!jQuery('#header').hasClass('-navActive')) {
            jQuery('#header.-willBeSticky').removeClass('sticky');
        }
    };

    stickyNav();

    jQuery(window).scroll(function () {
        stickyNav();
    });
});