import 'jquery';
import 'slick-carousel';
import '@fancyapps/fancybox';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';
import { default as fitvids } from 'fitvids';

jQuery(document).ready(function () {
    jQuery('.galleryList.-isCarousel:not(.-mediaText, .-card)').each(function () {
        const that = this;
        const controlsEl = jQuery(this).parent().find('.galleryMeta .galleryControls');

        let slickConf = {
            ...SLICK_CONF,
            arrows: true,
            appendDots: jQuery(this),
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            lazyLoad: 'ondemand',
        };

        if(controlsEl.length > 0)
            slickConf.appendArrows = jQuery(controlsEl);

        jQuery(this).not('.slick-initialized').slick(slickConf);

        jQuery(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if(jQuery(that).parent().find('.galleryHeadList .activeItem .text').length > 0)
                jQuery(that).parent().find('.galleryHeadList .activeItem .text').text(nextSlide + 1);
        });
    });

    // Fancybox.bind("[data-fancybox]", {
    //     infinite: true
    // });

    fitvids('.galleryList .videoContainer');
});
